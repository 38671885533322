import * as React from 'react'
import { Link } from 'gatsby'
import { backArrContainer, backArr, iconBox, navLinkIconSvg, musicSvg } from './back-arrow.module.css'

const appsIcon = <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
  <rect className={navLinkIconSvg} width="30" height="30" x="10" y="5" rx="0" />
  <rect className={navLinkIconSvg} width="30" height="30" x="10" y="55" rx="0" />
  <rect className={navLinkIconSvg} width="30" height="30" x="60" y="15" rx="0" />
  <rect className={navLinkIconSvg} width="30" height="30" x="60" y="65" rx="0" />
</svg>;

const musicIcon = <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" className={musicSvg}>
  <circle className={navLinkIconSvg} cx="28" cy="60" r="14"></circle>
  <circle className={navLinkIconSvg} cx="72" cy="78" r="14"></circle>
  <path className={navLinkIconSvg} d="M42,60 42,7 86,25 86,78"/>
</svg>;

const BackArrow = ({ to, children }) => {
  let svgIcon;
  if (children==='Apps') {svgIcon = appsIcon;}
  if (children==='Music') {svgIcon = musicIcon;}
  return (
    <div className={backArrContainer} title={"Back to "+children}>
      <Link to={to} className={backArr}>&lt;&nbsp;<div className={iconBox}>{svgIcon}</div></Link>
    </div>
  )
}

export default BackArrow
