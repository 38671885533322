import * as React from 'react'
import { useState } from 'react'
import BackArrow from '../../components/back-arrow'
import { centerHeading } from '../../components/layout.module.css'
import { productive5things, productiveContainer, productiveButton, unproductiveButton, theWinner } from '../../components/decide.module.css'

const OptionsForm = () => {
  const [isReady,setReady] = useState(false);
  const checkState = (e) => {
    let inputs = e.target.parentNode.getElementsByTagName("input"); let total=0;
    for (var i=0; i<inputs.length; i++) {
      inputs[i].classList.remove(theWinner);
      if (inputs[i].value !== '') { total++; } // update filled inputs count
    } if (total) { setReady(true); } else { setReady(false); }
    if (e.key === 'Enter') { selectItem(e); }
  }
  const selectItem = (e) => {
    let inputs = e.target.parentNode.getElementsByTagName("input"); let filledInputs = [];
    for (var i=0; i<inputs.length; i++) {
      inputs[i].blur();
      inputs[i].classList.remove(theWinner);
      if (inputs[i].value !== '') { filledInputs.push(inputs[i]); } // update filled inputs count
    }
    if (isReady) {
      let randIndex=Math.floor(Math.random()*filledInputs.length);
      filledInputs[randIndex].className = theWinner;
    }
  }
  return (
    <div className={productiveContainer}>
      <p>Think of up to five things you could be doing.<br/>Write them in the boxes below.</p>
      <form className={productive5things} onKeyUp={(e)=>checkState(e)} role="presentation">
        1&nbsp;&nbsp;<input type="text"></input><br/>
        2&nbsp;&nbsp;<input type="text"></input><br/>
        3&nbsp;&nbsp;<input type="text"></input><br/>
        4&nbsp;&nbsp;<input type="text"></input><br/>
        5&nbsp;&nbsp;<input type="text"></input><br/>
      </form>
      <p>Hit the button below to pick one at random.</p>
      <div className={isReady ? productiveButton : unproductiveButton} onClick={(e)=>selectItem(e)} role="presentation"></div>
      <p>Then go do that thing. Now!</p>
    </div>
  )
}

const DecidePage = () => {
  return (
    <main>
      <title>Decide &ndash; Evan Gedrich</title>
      <h1 className={centerHeading}>Decide</h1>
      <OptionsForm />
      <BackArrow to="/apps">Apps</BackArrow>
    </main>
  )
}

export default DecidePage
